<template>
  <div id="app">
    <navbar />
    <it-solutions />
    <Competition />
    <digital-change-over />
    <WeAreHere />
    <service-packages />
    <packages />
    <farmers />
    <contact/>
    <Footer/>
    <backtotop/>
  </div>
</template>



 
<!-- Load polyfills to support older browsers -->
<script src="//polyfill.io/v3/polyfill.min.js?features=es2015%2CIntersectionObserver" crossorigin="anonymous"></script>

<!-- Load Vue followed by BootstrapVue -->
<script src="//unpkg.com/vue@latest/dist/vue.min.js"></script>
<script src="//unpkg.com/bootstrap-vue@latest/dist/bootstrap-vue.min.js"></script>

<!-- Load the following for BootstrapVueIcons support -->
<script src="//unpkg.com/bootstrap-vue@latest/dist/bootstrap-vue-icons.min.js"></script>

<script>
import ItSolutions from './components/ItSolutions.vue';
import ServicePackages from "./components/ServicePackages.vue"
import navbar from "./components/navbar.vue"
import WeAreHere from "./components/WeAreHere.vue"
import digitalChangeOver from './components/digitalChangeOver.vue'
import Footer from './components/Footer.vue'
import contact from './components/contact.vue'
import backtotop from './components/backtotop.vue'
import Farmers from './components/Farmers.vue'
import packages from './components/packages.vue'
import Competition from './components/Competition.vue'

export default {
  name: 'App',
  components: {
    navbar,
    ItSolutions,
    digitalChangeOver,
    WeAreHere,
    Footer,
    ServicePackages,
    contact,
    backtotop,
    Farmers,
    packages,
    Competition
  }
}
</script>

<style lang="scss">
@import "./assets/sass/style.css";
@import '@/assets/sass/style.scss'
</style>
