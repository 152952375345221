<template>
<div class="farmers base-padding component">
    <div class="d-flex flex-column align-items-center">
        <h1 class="col-md-10 col-xl-8 text-center mb-5">Tartozzon Ön is az elégedett gazdák közé, akik a mi rendszerünket használják!</h1>
        <div class="d-flex justify-content-center flex-wrap w-100">
            <farmer-card v-for="(farmer, index) in farmers" :key="index" :farmer="farmer"></farmer-card>
        </div>
    </div>
</div>
</template>

<script>
import FarmerCard from './FarmerCard'
export default {
    components: {
        FarmerCard
    },
    data() {
        return {
            farmers: [
                {
                    img: "Hugyi Zalán.png",
                    name: "Hugyi Zalán",
                    farm: "Családi gazdaság",
                    description: "A gyorsan változó éghajlatváltozás miatt olyan rendszerre van szükségem, amivel többet tudok kihozni a gazdaságomból, és ebben tudott segíteni nekem az AGRÁRTECH. Ugyanazon a területen kevesebbet kell dolgoznom és többet tudok a családommal lenni. A jövőt és növekedést látom a rendszerben!"
                },
                {
                    img: "Jancsik Péter.png",
                    name: "Jancsik Péter",
                    farm: "Jancsik Zöldség Farm Kft.",
                    description: "Számomra különösen fontos a növényvédelem és a hatékony permetezés. Az AGRÁRTECH rendszer segítségével gyorsan és pontosan megtudhatom, mely területekre kell jobban összpontosítani, így sokkal hatékonyabban építhetem a cégem."
                },
                 {
                    img: "Herczeg János.png",
                    name: "Herczeg János",
                    farm: "Herczeg János EV",
                    description: "Minden nap egy új kihívás volt a AGRÁRTECH előtt. A rendszer nyújtotta információ mennyisége lehetővé teszi, hogy a teljesítményünket kiszámíthatóan tudjuk növelni és a környezeti hatásokkal szemben jobban fel tudjunk készülni, így jövőnk jól tervezhető."
                }
            ]
        }
    }
}
</script>
