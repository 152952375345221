<template>
<div class="col-12 d-flex justify-content-center">
    <div class="card card-mobile-screen shadow">
        <div class="img-container">
            <b-img :src="require(`../assets/img/servicePackages/${servicePackage.img}`)" :alt="`${servicePackage.title}`" :title="`${servicePackage.title}`" class="card-img-top" />
        </div>
        <div class="text d-flex align-items-center">{{servicePackage.title}}</div>

        <b-collapse :id="servicePackage.img" class="details">
            <div class="description" v-html="servicePackage.description"></div>
            <a href="#kapcsolat" class="text-center">
                <div class="pt-3"><img src="../assets/img/modal/pop-up-ajanlat-ikon.svg" alt="További ajánlat" title="További ajánlat" width="25" type="button" class="play-button"></div>
                <div><u class="offers px-3">További ajánlatokért keresse szakértőinket!</u></div>
            </a>
        </b-collapse>
        <span v-b-toggle="`${servicePackage.img}`" class="icon-container d-flex align-items-end">
            <img src="../assets/img/servicePackages/zold_doboz_fehernyil.svg" class="icon up" alt="Nyíl ikon" title="Nyíl ikon">
            <img src="../assets/img/servicePackages/mobil_zold_ikon.svg" class="icon down" alt="Mobil zöld ikon" title="Mobil zöld ikon">
        </span>
    </div>
</div>
</template>

<script>
export default {
    props: ["servicePackage"]
}
</script>
