<template>
<div class="col-sm-8 col-lg-4 d-flex justify-content-center">
    <div class="card card-big-screen shadow" @click="$bvModal.show(servicePackage.title)">
        <div class="img-container">
            <b-img :src="require(`../assets/img/servicePackages/${servicePackage.img}`)" :alt="`${servicePackage.title}`" :title="`${servicePackage.title}`" class="card-img-top" />
        </div>
        <div class="text">{{servicePackage.title}}</div>
        <img src="../assets/img/servicePackages/zold_doboz_fehernyil.svg" class="icon" alt="Nyíl ikon" title="Nyíl ikon">
    </div>
</div>
</template>

<script>
export default {
    props: ["servicePackage"]
}
</script>
