var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"packages component",attrs:{"id":"csomagjaink"}},[_c('div',{staticClass:"d-flex title-container"},[_c('img',{staticClass:"bg-img bg-img-large",attrs:{"src":require("../assets/img/packages/csomagjaink_bg_2560.png"),"alt":"Informatikai megoldások a mezőgazdaságban"}}),_c('img',{staticClass:"bg-img bg-img-big",attrs:{"src":require("../assets/img/packages/csomagjaink_bg_1920.png"),"alt":"Informatikai megoldások a mezőgazdaságban"}}),_c('img',{staticClass:"bg-img bg-img-medium",attrs:{"src":require("../assets/img/packages/csomagjaink_bg_1360.png"),"alt":"Informatikai megoldások a mezőgazdaságban"}}),_c('img',{staticClass:"bg-img bg-img-small",attrs:{"src":require("../assets/img/packages/csomagjaink_bg_768.png"),"alt":"Informatikai megoldások a mezőgazdaságban"}}),_c('img',{staticClass:"bg-img bg-img-mobile",attrs:{"src":require("../assets/img/packages/csomagjaink_bg_372.png"),"alt":"Informatikai megoldások a mezőgazdaságban"}}),_c('div',{staticClass:"header-text d-flex align-items-start flex-column mt-xl-4 mt-3 base-padding w-100"},[_c('h1',{staticClass:"packageTitle mb-lg-3 col-12 col-sm-9 col-xl-8"},[_vm._v("Válasszon csomagjaink közül!")]),_vm._m(0),_vm._m(1),_c('div',{staticClass:"col-8 col-md-12"},[_c('more-info-button',{staticClass:"d-none d-md-block mt-md-0"})],1)])]),_c('div',{staticClass:"main-section d-flex flex-column"},[_c('div',{staticClass:"packagesAndOffersTitle align-self-center mt-3"},[_vm._v(" Csomagjaink, ajánlataink ")]),_vm._m(2),_vm._m(3),_c('div',{staticClass:"mt-3 d-flex flex-row flex-wrap justify-content-around pb-3 base-padding"},[_vm._l((_vm.getPackages),function(packageInfo,index){return _c('package-card',{key:index,attrs:{"packageInfo":packageInfo}})}),_vm._l((_vm.getPackages),function(packageInfo){return _c('package-card-mobile',{key:packageInfo.headerImg,attrs:{"packageInfo":packageInfo}})})],2),_vm._m(4),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subHeader h1 col-12 col-sm-9 col-xl-8"},[_vm._v("Használja az "),_c('span',{staticClass:"agrartech"},[_vm._v("agrártech")]),_vm._v(" nyújtotta előnyöket!")])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"features mt-lg-3 col-12 sm-9"},[_c('li',[_vm._v("Szaktanácsadás a mezőgazdaság minden területén")]),_c('li',[_vm._v("Precíziós Tápanyagtervezés")]),_c('li',[_vm._v("Országos RTK hálózat")]),_c('li',[_vm._v("Naprakész kimutatások")]),_c('li',[_vm._v("Profitnövelés az informatika segítségével")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"packageTypeSelector px-0 col-10 my-4 col-md-8 col-lg-5 col-xl-4 d-flex flex-row flex-wrap align-self-center"},[_c('div',{staticClass:"packageOffers pl-2 col-12 col-sm-6"},[_vm._v("Csomagajánlataink")]),_c('div',{staticClass:"customPackages col-12 col-sm-6"},[_c('a',{staticClass:"customPackagesLink",attrs:{"href":"#kapcsolat"}},[_vm._v("Egyéni csomag")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"currentlySelectedPackage d-flex flex-column col-10 col-md-6 align-self-center"},[_c('div',[_vm._v(" A most kiválasztott "),_c('br',{staticClass:"d-md-none"}),_vm._v(" (2021. augusztus 1. - 2021. szeptember 1.) ")]),_c('div',[_vm._v(" Starter csomagok előfizetési díjából "),_c('b',[_vm._v(" 10% kedvezményt ")]),_vm._v(" vehet igénybe az első évben. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"moreOffers align-self-center"},[_c('a',{attrs:{"href":"#kapcsolat"}},[_vm._v("További ajánlatokért keresse szakértőinket!")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"baseBackground"},[_c('img',{staticClass:"bg-img bg-img-large",attrs:{"src":require("../assets/img/packages/csomagjaink__also_bg_2560.jpg"),"alt":"Informatikai megoldások a mezőgazdaságban"}}),_c('img',{staticClass:"bg-img bg-img-big",attrs:{"src":require("../assets/img/packages/csomagjaink__also_bg_1920.jpg"),"alt":"Informatikai megoldások a mezőgazdaságban"}}),_c('img',{staticClass:"bg-img bg-img-medium",attrs:{"src":require("../assets/img/packages/csomagjaink__also_bg_1360.jpg"),"alt":"Informatikai megoldások a mezőgazdaságban"}}),_c('img',{staticClass:"bg-img bg-img-small",attrs:{"src":require("../assets/img/packages/csomagjaink__also_bg_768.jpg"),"alt":"Informatikai megoldások a mezőgazdaságban"}}),_c('img',{staticClass:"bg-img bg-img-mobile",attrs:{"src":require("../assets/img/packages/csomagjaink__also_bg_372.jpg"),"alt":"Informatikai megoldások a mezőgazdaságban"}})])
}]

export { render, staticRenderFns }