<template>
<div id="informatikai_megoldasok" class="it-solutions">
    <div class="title-container">
        <img src="../assets/img/background/fejlec_kep_egyben_2560.png" alt="Informatikai megoldások a mezőgazdaságban" title="Informatikai megoldások a mezőgazdaságban" class="bg-img bg-img-large">
        <img src="../assets/img/background/fejlec_kep_egyben_up_1920.png" alt="Informatikai megoldások a mezőgazdaságban" title="Informatikai megoldások a mezőgazdaságban" class="bg-img bg-img-big">
        <img src="../assets/img/background/fejlec_kep_egyben_1360.png" alt="Informatikai megoldások a mezőgazdaságban" title="Informatikai megoldások a mezőgazdaságban" class="bg-img bg-img-medium">
        <img src="../assets/img/background/fejlec_kep_egyben_768.png" alt="Informatikai megoldások a mezőgazdaságban" title="Informatikai megoldások a mezőgazdaságban" class="bg-img bg-img-small">
        <img src="../assets/img/background/fejlec_kep_egyben_372.png" alt="Informatikai megoldások a mezőgazdaságban" title="Informatikai megoldások a mezőgazdaságban" class="bg-img bg-img-mobile">

        <div class="title d-flex base-padding">
            <div class="col-12 col-lg-7 col-xl-6 ">
                <h1 class="custom-text-shadow agt-title ">Informatikai megoldások a mezőgazdaságban</h1>
                <more-info-button />
            </div>
        </div>


         <div class="title comp-title d-flex mb-5">
            <div class="col-12 text-right p-0">
                <div class="imageBox " @click="$bvModal.show('competition-popup')">
                    <div class="imageInn">
                        <b-img  class="imagePopup" :src="require('../assets/img/competition/agrartech_palyazat_inzert_aktiv.png') " alt="pályázat inzert" ></b-img>
                    </div>
                    <div class="hoverImg" >
                        <b-img  class="imagePopup" :src="require('../assets/img/competition/agrartech_palyazat_inzert_inaktiv.png')" alt="pályázat inzert"></b-img>                  
                    </div>
                </div>     
            </div>
        </div>

    </div>

    <div class="lower-line d-flex flex-wrap flex-row-reverse justify-content-center justify-content-lg-end base-padding mb-3">
        <div class="col-lg-8">
            <img src="../assets/img/azonnali-kimutatas.png" alt="Azonnali kimutatások akár a korábbi évekre visszamenőleg is" title="Azonnali kimutatások akár a korábbi évekre visszamenőleg is" class="w-100">
        </div>
        <div class="col-lg-4 align-self-end align-self-xl-center">
            <div class="text mt-lg-5 pb-4 pb-lg-0 pt-lg-5">Azonnali kimutatások akár a korábbi évekre visszamenőleg is</div>
        </div>
    </div>
    <img src="../assets/img/background/mezo_bg.jpg" alt="mező" title="mező" class="w-100">

</div>
</template>

<script>
import MoreInfoButton from './MoreInfoButton'
import Competition from './Competition'


export default {
    components: {
        MoreInfoButton,
        Competition
        
    }
    
}
</script>
