<template>
<div class="navbar">

  <b-modal id="login-popup" centered size="l" hide-header hide-footer>
    <div class="competition">
      <div class="contact-form align-items-center shadow">
        <div class="d-flex flex-row flex-wrap align-items-stretch ">
          <div class="col-12  p-4">
            <div class="form h-100">
              <div class="text-left">
                <img src="../assets/img/modal/pop-up-bezar-ikon.svg" alt="Kilépés" title="kilépés"
                     width="25" class="align-self-end" @click="$bvModal.hide('login-popup')"
                     type="button">
              </div>
              <h5 class="competition-text-green">Felhasználó fiók</h5>
              <h3 class="competition-text">Bejelentkezés</h3>
              <div v-if="loginMsg == ''">
                  <div class="form-group my-3">
                    <input required v-model="loginForm.pgrUsername" type="text" class="form-control"
                           :disabled="loginLoader"
                           name="company" id="company" placeholder="Felhasználónév">
                  </div>

                  <div class="form-group my-3">
                    <input required v-model="loginForm.pwd" type="password" class="form-control"
                           :disabled="loginLoader"
                           name="company" id="company" placeholder="Jelszó">
                  </div>


                  <div class="form-group  text-center">
                    <button type="submit" @click="login" class="button py-2 px-5"
                            :disabled="loginLoader">
                      <span v-show="loginLoader" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      Bejelentkezés </button>
                  </div>
                </div>
              <div v-else>
                <h4 class="competition-text">{{ loginMsg }}</h4>
                <div class="form-group  text-center" v-if="user.agrartechToken == ''">
                  <button type="submit" @click="loginBack" class="button py-2 px-5">
                    Vissza </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>

  <b-modal id="user-popup" centered size="l" hide-header hide-footer>
  <div class="competition">
    <div class="contact-form align-items-center shadow">
      <div class="d-flex flex-row flex-wrap align-items-stretch ">
        <div class="col-12 p-4">
          <div class="form h-100">
            <div class="text-left">
              <img src="../assets/img/modal/pop-up-bezar-ikon.svg" alt="Kilépés" title="kilépés"
                   width="25" class="align-self-end" @click="$bvModal.hide('user-popup')"
                   type="button">
            </div>
            <h5 class="competition-text-green">Felhasználó fiók</h5>
            <h3 class="competition-text">Név: {{ user.username }}<br />Felhasználónév: {{ user.pgrUsername }}</h3><br /><br />
            <div class="form-group text-center">
              <form method="GET" target="_blank" id="pgr-form" action="https://pgr.hu/alkalmazasok">
                <input
                    type="hidden"
                    name="_token"
                    :value="user.agrartechToken"
                />
                <button type="submit" class="button py-2 px-5">
                  Precíziós adatok megtekintése </button>
              </form>
            </div>
            <div class="form-group text-center">
              <button class="button py-2 px-5" @click="logout">
                Kijelentkezés</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</b-modal>

    <b-navbar class="bd-navbar justify-content-center" toggleable="lg" type="dark" variant="dark">
        <b-navbar-brand href="#informatikai_megoldasok">
            <img src="../assets/img/agrartech-logo.png" alt="Agrartech logo" title="Agrartech logo" class="navbar-logo">
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse">

        </b-navbar-toggle>

    <b-collapse id="nav-collapse" class="text-center justify-content-end" is-nav>
      <b-navbar-nav >
        <b-nav-item href="#informatikai_megoldasok">Informatika a mezőgazdaságban</b-nav-item>
        <b-nav-item href="#digitalis_atallas">Digitális átállás</b-nav-item>
        <b-nav-item href="#jelen_vagyunk">Jelen vagyunk</b-nav-item>
        <b-nav-item href="#szolgaltatasok">Szolgáltatások</b-nav-item>
        <b-nav-item href="#csomagjaink">Csomagjaink</b-nav-item>
        <b-nav-item href="#kapcsolat">Kapcsolat</b-nav-item>
        <b-nav-item v-if="user.agrartechToken == ''" @click="$bvModal.show('login-popup')">Belépés</b-nav-item>
        <b-nav-item v-else @click="$bvModal.show('user-popup')">Fiókom</b-nav-item>
      </b-navbar-nav>

            <!-- Right aligned nav items -->
            <b-navbar-nav>

            </b-navbar-nav>
        </b-collapse>
    </b-navbar>

</div>
</template>

<script>
import axios from 'axios';

export default {
  name: "navbar",
  data() {
    return {
      loginForm: {
        pgrUsername: "",
        pwd: "",
      },
      user: {
        username: "",
        pgrUsername: "",
        agrartechToken: "",
      },
      loginMsg: "",
      loginError: "",
      loginLoader: false,
    }
  },

  mounted() {
    this.getUserData();
  },

  methods: {
    getUserData() {
      axios.post(
          'getUserData.php',
      )
          .then(response => {
            if (response.data.user !== undefined) {
              this.user = response.data.user;
            }
          })
          .catch(e => {
            console.log(e)
          });
    },

    loginBack() {
      this.loginError = '';
      this.loginMsg = '';
    },

    logout() {
      axios.post(
          'logout.php',
      )
          .then(response => {
            this.user = {
              username: "",
                  pgrUsername: "",
                  agrartechToken: "",
            };
            this.loginError = '';
            this.loginMsg = '';

            this.$bvModal.hide('user-popup');
          })
          .catch(e => {
            console.log(e)
          });
    },

    login() {
      this.loginLoader = true;
      axios.post(
          'login.php',
          this.loginForm,
      )
      .then(response => {
        if (response.data.error === undefined) {
          this.user = response.data.user;
          this.loginError = response.data.error;
          this.loginForm = {
            pgrUsername: "",
                pwd: "",
          };
        }
        this.loginMsg = response.data.msg;
        this.loginLoader = false;
        setTimeout(() => this.$bvModal.hide('login-popup'), 3000);
      })
      .catch(e => {
        console.log(e)
      });
    },
  },
}
</script>
