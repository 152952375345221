<template>
<div class="col-sm-8 col-lg-4 d-flex justify-content-center">
    <div class="card card-big-screen border-0 mb-5 align-self-sm-center align-self-lg-baseline">
        <div class="d-flex justify-content-center title-img-container">
            <b-img :src="require(`../assets/img/packages/packagePerks/${packageInfo.headerImg}`)" alt="" class="title-img" />
        </div>
        <div class="card-header d-flex justify-content-center">
            <div class="col-10 text-center">
                {{packageInfo.headerText}}
            </div>
        </div>
        <div v-html="packageInfo.content"></div>
    </div>
</div>
</template>

<script>
export default {
    props: ["packageInfo"]
}
</script>