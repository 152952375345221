<template>
    <div id="digitalis_atallas" class="component">
        <div class="title-container d-flex justify-content-sm-center">
            <img src="../assets/img/DigitalChangeOver/tervezhetoseg_bg_2560.png" alt="Háttérkép" title="Háttérkép" class="bg-img bg-img-large" />
            <img src="../assets/img/DigitalChangeOver/tervezhetoseg_bg_1960.png" alt="Háttérkép" title="Háttérkép" class="bg-img bg-img-big" />
            <img src="../assets/img/DigitalChangeOver/tervezhetoseg_bg_1360.png" alt="Háttérkép" title="Háttérkép" class="bg-img bg-img-medium custom-padding-top" />
            <img src="../assets/img/DigitalChangeOver/tervezhetoseg_bg_768.png" alt="Háttérkép" title="Háttérkép" class="bg-img bg-img-small custom-padding-top" />
            <img src="../assets/img/DigitalChangeOver/tervezhetoseg_bg_372.png"  alt="Háttérkép" title="Háttérkép" class="bg-img bg-img-mobile custom-padding-top" />
            
            <div class="digitalChangeOverContainer base-padding w-100">
                <div class="mb-md-3 d-flex flex-lg-row flex-wrap">
                    <h2 class="digitalTitle agt-title col-12 col-lg-8 text-center text-md-left align-self-center d-block">
                        A digitális átállással gyorsan, precízen juthat a tényszerű adatokhoz.
                    </h2>
                </div>
                <div  class="d-flex flex-xl-row flex-column align-items-center">
                    <div class="col-xl-3 col-lg-8 col-10 align-self-md-start">
                        <div id="greenText" class="mt-lg-5">
                            Az 
                            <span style="color: black">AGRÁRTECH</span> 
                            digitális rendszere segít átlépni a jelenből a 
                            <span style="color: black">jövő</span> 
                            technológiájába
                        </div>
                        <more-info-button  class="mb-3 mb-md-0 align-self-baseline d-none d-lg-block"/>
                    </div>
                    
                    <div class="col-lg-9 col-10 ml-lg-5-4 ps-3 ">
                        <img class="digitalChangeOverImg img-fluid" src="../assets/img/DigitalChangeOver/digitalis_atallas.png" alt="Digitális átállás" title="Digitális átállás" />
                    </div>
                    
                </div>
                <div class="d-flex flex-column align-items-center lower-section"> 
                    <div class="col-12 mt-0 mt-xl-3 mb-xl-5 text-center whiteSubHeader agt-title custom-text-shadow" >
                        Optimalizálja és növelje a termés mennyiségét a cloud alapú informatikai rendszerünkkel
                    </div>
                    <div class="mt-0">
                        <img src="../assets/img/DigitalChangeOver/tervezhetoseg_kep_1920.png" class="bg-img-big bg-img-large tervezhetosegImg "  alt="Digitális átállás tervezhetőség" title="Digitális átállás tervezhetőség"/>
                        <img src="../assets/img/DigitalChangeOver/tervezhetoseg_kep_768.png"  class="bg-img-medium tervezhetosegImg" alt="Digitális átállás tervezhetőség" title="Digitális átállás tervezhetőség" />
                        <img src="../assets/img/DigitalChangeOver/tervezhetoseg_kep_372.png"  class="bg-img-mobile tervezhetosegImg "  alt="Digitális átállás tervezhetőség" title="Digitális átállás tervezhetőség" />
                    </div>
                    <div class="tervezhetosegText">
                        tervezhetőség
                    </div>
                </div>
            </div>               
        </div>

        
    </div>
</template>

<script>
import MoreInfoButton from './MoreInfoButton.vue'

export default {
    components: {
        MoreInfoButton,
    }
}
</script>

