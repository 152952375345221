<template>
<div class="col-sm-8 col-lg-4 d-flex justify-content-center mb-5">
    <div class="card shadow">
        <b-img title="Farmer neve" :src="require(`../assets/img/farmers/${farmer.img}`)" :alt="`farmer.name`" class="card-img-top" />
        <div class="p-3">
            <div class="name pb-1">{{farmer.name}}</div>
            <div class="pb-2">{{farmer.farm}}</div>
            <div class="description">{{farmer.description}}</div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: ["farmer"]
}
</script>
