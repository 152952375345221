<template>
<div>
    <b-modal v-for="servicePackage in servicePackages" :key="servicePackage.title" :id="servicePackage.title" centered size="xl" hide-header hide-footer>
        <div class="d-flex flex-column justify-content-between modalMainDiv">
            <img src="../assets/img/modal/pop-up-bezar-ikon.svg" alt="Kilépés" title="kilépés" width="25" class="align-self-end" @click="$bvModal.hide(servicePackage.title)" type="button">
            <h5>
                {{servicePackage.title}}
            </h5>
            <div class="img-container">
                <b-img :src="require(`../assets/img/modal/${servicePackage.modalImg}`)" :alt="`${servicePackage.title}`" />
            </div>
            <div class="description" v-html="servicePackage.description"></div>
            <a href="#kapcsolat" @click="$bvModal.hide(servicePackage.title)" class="align-self-end more-offer">
                <img src="../assets/img/modal/pop-up-ajanlat-ikon.svg" alt="További ajánlat" title="További ajánlat" width="25" type="button">
                <span>További ajánlatokért keresse szakértőinket!</span>
            </a>
            <img src="../assets/img/modal/pop-up-zold-ikon.svg" alt="Ikon" title="ikon" @click="$bvModal.hide(servicePackage.title)" type="button" width="120" class="align-self-center icon">
        </div>
    </b-modal>
</div>
</template>

<script>
export default {
    props: ["servicePackages"]
}
</script>
