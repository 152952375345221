<template>
    <div class="competition-popup-container">
        <b-modal id="competition-popup" centered size="xl" hide-header hide-footer>
            <div class="competition">
                <div class="contact-form align-items-center shadow">
                    <div class="d-flex flex-row flex-wrap align-items-stretch ">
                        <div class="col-12 col-md-6 col-lg-8  p-4">
                            <div class="form h-100">
                                <div class="text-left">
                                    <img src="../assets/img/modal/pop-up-bezar-ikon.svg" alt="Kilépés" title="kilépés"
                                        width="25" class="align-self-end" @click="$bvModal.hide('competition-popup')"
                                        type="button">
                                </div>
                                <h5 class="competition-text-green">VP2-4.1.8-21 </h5>
                                <h5 class="competition-text-green">MEZŐGAZDASÁG DIGITÁLIS ÁTÁLLÁSHOZ </h5>
                                <h3 class="competition-text">Segítünk, hogy a digitális átállás gyors és <br> egyszerű
                                    legyen! Regisztráljon most és mi <br> felvesszük Önnel a kapcsolatot.</h3>
                                <form method="post" action="mail.php" id="contactForm" name="contactForm"
                                    novalidate="novalidate">

                                    <div class="form-group my-3">
                                        <input required v-model="emailForm.company" type="text" class="form-control"
                                            name="company" id="company" placeholder="Cégnév">
                                    </div>

                                    <div class="form-group my-3">
                                        <input required v-model="emailForm.place" type="text" class="form-control"
                                            name="place" id="place" placeholder="Székhely">
                                    </div>

                                    <div class="form-group my-3">
                                        <input required v-model="emailForm.regnum" type="text" class="form-control"
                                            name="regnum" id="regnum" placeholder="Cégjegyzékszám">
                                    </div>

                                    <div class="form-group my-3">
                                        <input required v-model="emailForm.tax" type="text" class="form-control"
                                            name="tax" id="tax" placeholder="Adószám">
                                    </div>

                                    <div class="form-group my-3">
                                        <input required v-model="emailForm.name" type="text" class="form-control"
                                            name="name" id="name" placeholder="Kapcsolattartó Név">
                                    </div>

                                    <div class="form-group my-3">
                                        <input required v-model="emailForm.tel" type="text" class="form-control"
                                            name="tel" id="tel" placeholder="Kapcsolattartó Telefonszám">
                                    </div>

                                    <div class="form-group my-3">

                                        <input required :class="{'invalidEmailAddress': !validateEmail} "
                                            v-model="emailForm.email" type="text" class="form-control" name="email"
                                            id="email" placeholder="E-mail cím">
                                    </div>

                                    <div class="form-group my-3">
                                        <textarea v-model="emailForm.message" class="form-control" name="message"
                                            id="message" cols="30" rows="4" placeholder="Megjegyzés"></textarea>
                                    </div>

                                    <input type="hidden" name="competition" value="competition">

                                    <div v-if="urlHasEmailPath" class="text-center"> 
                                        <p
                                            :class="successfullEmailSend ? 'successFullEmailSend' : 'unsuccessFullEmailSend'">
                                            {{emailStateMessage}}
                                        </p>
                                    </div>

                                    <div>
                                        <div class="form-group  text-center">
                                            <button type="submit" value="submit" class="button py-2 px-5">
                                                Regisztráció </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div class="col-12 col-md-6 col-lg-4 p-0">
                            <div class="competition-info h-100 w-100 d-flex flex-column justify-content-between">

                                <div class="d-flex align-items-center justify-content-between">
                                    <b-img class="col-12 w-75 mb-5 mt-5 text-center"
                                        :src="require('../assets/img/competition/agrartech_logo.svg') "></b-img>
                                </div>

                                <div>

                                    <div class="d-flex align-items-center justify-content-between my-3">
                                        <div class="col-1 text-center">
                                            <b-img class="pl-1" alt="Telefon ikon" title="Telefon ikon"
                                                :src="require('../assets/img/competition/smartphone.svg') "></b-img>
                                        </div>
                                        <a class="text col-10 text-white" href="tel:36-70-776-1290">+36 70 776 1290</a>


                                    </div>

                                    <div class="d-flex align-items-center justify-content-between my-3">
                                        <div class="col-1 text-center">
                                            <b-img alt="Email ikon" title="Email ikon"
                                                :src="require('../assets/img/competition/email.svg')"></b-img>
                                        </div>
                                        <a class="text col-10 text-white"
                                            href="mailto:digitalisatallas@csihazrt.hu">digitalisatallas@csihazrt.hu </a>
                                    </div>

                                    <div class="d-flex align-items-center justify-content-between my-3">
                                        <div class="col-1 text-center">
                                            <b-img alt="Térkép ikon" title="Térkép ikon"
                                                :src="require('../assets/img/competition/maps-and-flags.svg')"></b-img>
                                        </div>
                                        <span class="text col-10">6724 Szeged <br> Kossuth Lajos sgt. 52.</span>
                                    </div>
                                </div>
                                <div>

                                    <a href="https://www.palyazat.gov.hu/node/73792#" target="blank">
                                        <b-img class="w-100 sz-logo"
                                            :src="require('../assets/img/competition/szechenyi_2020.png') ">
                                        </b-img>
                                    </a>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="col-12 col-md-12 col-lg-8 p-0 d-flex justify-content-center">
                    <div class="col-11 col-md-7 col-lg-8 text-center">

                        <h3 class="competition-text">Segítünk, hogy a digitális átállás gyors és egyszerű
                            legyen! Regisztráljon most és mi felvesszük Önnel a kapcsolatot.</h3>
                        <form method="post" action="mail.php" id="contactForm" name="contactForm"
                            novalidate="novalidate">

                            <div class="form-group my-3">
                                <input required v-model="emailForm.company" type="text" class="form-control" name="company" id="company"
                                    placeholder="Cégnév">
                            </div>

                            <div class="form-group my-3">
                                <input required v-model="emailForm.place" type="text" class="form-control" name="place" id="place"
                                    placeholder="Székhely">
                            </div>

                            <div class="form-group my-3">
                                <input required v-model="emailForm.regnum" type="text" class="form-control" name="regnum" id="regnum"
                                    placeholder="Cégjegyzékszám">
                            </div>

                            <div class="form-group my-3">
                                <input required v-model="emailForm.tax" type="text" class="form-control" name="tax" id="tax"
                                    placeholder="Adószám">
                            </div>

                            <div class="form-group my-3">
                                <input required v-model="emailForm.name" type="text" class="form-control" name="name" id="name"
                                    placeholder="Kapcsolattartó Név">
                            </div>

                            <div class="form-group my-3">
                                <input required v-model="emailForm.tel" type="text" class="form-control" name="tel" id="tel"
                                    placeholder="Kapcsolattartó Telefonszám">
                            </div>

                            <div class="form-group my-3">

                                <input required :class="{'invalidEmailAddress': !validateEmail} " v-model="emailForm.email"
                                    type="text" class="form-control" name="email" id="email" placeholder="E-mail cím">
                            </div>

                            <div class="form-group my-3">
                                <textarea v-model="emailForm.message" class="form-control" name="message" id="message"
                                    cols="30" rows="4" placeholder="Megjegyzés"></textarea>
                            </div>

                            <input type="hidden" name="competition" value="competition">

                            <div v-if="urlHasEmailPath">
                                <p :class="successfullEmailSend ? 'successFullEmailSend' : 'unsuccessFullEmailSend'">
                                    {{emailStateMessage}}
                                </p>
                            </div>

                            <div>
                                <div class="form-group  text-center">
                                    <button  type="submit" value="submit" class="button py-2 px-5">
                                        Regisztráció </button>     
                                </div>
                            </div>
                        </form>
                           
                    </div>   
                </div>

           <div class="d-flex flex-row flex-wrap-reverse align-items-stretch ">  
                <div class=" col-12 col-md-12 col-lg-4 p-0 left-contact">
                    <div class="d-flex align-items-center justify-content-center mb-5 mt-5">
                        <b-img class="w-75 mb-2 mt-2" :src="require('../assets/img/competition/agrartech_logo.svg') "></b-img>
                    </div>

                    <div class="d-flex align-items-center justify-content-center  mb-4 mt-4">
                        <div class="col-12 text-center">
                            <b-img alt="Telefon ikon" title="Telefon ikon"
                                :src="require('../assets/img/competition/smartphone.svg') "></b-img>
                            <a class="text col-6 text-white
                            " href="tel:36-70-776-1290">+36 70 776 1290</a>
                        </div>
                        
                    </div>

                    <div class="d-flex align-items-center justify-content-center mb-4">
                        <div class="col-12 text-center">
                            <b-img alt="Email ikon" title="Email ikon"
                                :src="require('../assets/img/competition/email.svg')"></b-img>
                                <a class="text col-8 text-white
                            " href="mailto:digitalisatallas@csihazrt.hu">digitalisatallas@csihazrt.hu </a>
                        </div>
                        
                    </div>

                    <div class="d-flex align-items-center justify-content-center">
                        <div class="col-12 text-center">
                          
                             <b-img class="col-2" alt="Térkép ikon" title="Térkép ikon"
                                :src="require('../assets/img/competition/maps-and-flags.svg')"></b-img>

                            
                            <span class="text col-8 text-white">6724 Szeged,Kossuth Lajos sgt. 52.</span> 

                        </div>
                        
                    </div>

                     <div class="d-flex align-items-center justify-content-center">
                         <div class="col-12 text-center">
                             <a href="https://www.palyazat.gov.hu/node/73792#" target="blank">    
                             <b-img  class="sz-logo" :src="require('../assets/img/competition/szechenyi_2020.png') ">
                            </b-img></a>
                        </div>
                    </div>
            </div>
                 <div class="text-right">
                        <img src="../assets/img/modal/pop-up-bezar-ikon.svg" alt="Kilépés" title="kilépés" width="25"
                            class="align-self-end" @click="$bvModal.hide('competition-popup')" type="button">
                 </div> 
                <div >
            </div>   
            </div>  -->
        </b-modal>


        <b-modal id="email-sent-modal" content-class="h-75" size="sm" title="Email küldés" centered hide-footer>
            {{emailStateMessage}} </b-modal>
    </div>
</template>

<script>
    export default {
        name: "contact",
        data() {
            return {
                emailForm: {
                    name: "",
                    email: "",
                    message: "",
                    company: "",
                    regnum: "",
                    tax: "",
                    place: "",
                    tel: ""

                },
                successfullEmailSend: false,
                isEmailAddressValid: false,
                emailStateMessage: ''
            }
        },
        computed: {
            validateEmail() {
                if (this.emailForm.email === "")
                    return true;
                const regEx =
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return regEx.test(this.emailForm.email);
            },
        },
        methods: {
            urlHasEmailPath() {
                const urlHasEmailPath = (document.location.href.includes("sikeres#informatikai_megoldasok") || document
                    .location.href.includes("sikertelen#informatikai_megoldasok"));
                if (urlHasEmailPath) {

                    this.showErrorModal();



                }
                return urlHasEmailPath;
            },
            showErrorModal() {
                this.$bvModal.show('email-sent-modal')
                console.log('asda');


            }
        },
        created() {
            if (document.location.href.includes("sikertelen#informatikai_megoldasok")) {
                this.emailStateMessage = "Az üzenetet nem sikerült elküldeni. Kérjük, próbálja újra később.";
                this.successfullEmailSend = false;
            } else if (document.location.href.includes("sikeres#informatikai_megoldasok")) {
                this.emailStateMessage = "Üzenetét sikeresen továbbítottuk.";
                this.successfullEmailSend = true;
            }
        },
        mounted() {
            this.urlHasEmailPath()
        }
    }
</script>