<template>
<div id="szolgaltatasok" class="service-packages component">
    <div class="title-container">
        <img src="../assets/img/background/mezo_bg.jpg" class="bg-img" alt="Mező háttérkép" title="Mező háttérkép">
        <h1 class="title custom-text-shadow">Agrártech MIR - Szolgáltatáscsomagok</h1>
    </div>
    <div class="d-flex flex-wrap justify-content-center card-container base-padding">
        <service-package-card v-for="(servicePackage, index) in servicePackages" :key="index" :servicePackage="servicePackage"></service-package-card>
        <service-package-card-mobile v-for="servicePackage in servicePackages" :key="servicePackage.title" :servicePackage="servicePackage"></service-package-card-mobile>
    </div>
    <service-package-pop-up :servicePackages="servicePackages"></service-package-pop-up>
</div>
</template>

<script>
import ServicePackageCard from './ServicePackageCard'
import ServicePackagePopUp from './ServicePackagePopUp'
import ServicePackageCardMobile from './ServicePackageCardMobile'


export default {
    components: {
        ServicePackageCard,
        ServicePackagePopUp,
        ServicePackageCardMobile
    },
    data() {
        return {
            servicePackages: [{
                    title: "Gépüzemeltetési analízis",
                    img: "gepuzemeltetesi_analizis.png",
                    modalImg: "gepuzemeltetes_pop_up.png",
                    description: `<div>Partnereink JDLink szolgáltatásra alkalmas gépeinek előző napi munkájáról jelentést készítünk, amely elérhető az <b>Agrártech MIR</b> felhasználói fiókjában. A jelentés tartalmazza a gép előző napi útvonalát, üzemanyagfogyasztását, teljesített üzemóráját, esetleges karbantartási riasztásokat, gépterheltséget. Valamennyi rögzített adat elérhető, visszakereshető partnerünk számára a szolgáltatás teljes ideje alatt.</div>`
                },
                {
                    title: "Gép- és adatgyűjtés-beállítás",
                    img: "gep_es_adatgyujtes_beallitas.png",
                    modalImg: "gep_es_adatgyujtes_beallitas_pop_up.png",
                    description: `<div>A <b>precíziós  gépek  fizikai  méretezésekor</b> minden  kapott  adatot  rögzítünk  a  gép  monitorjába (csatlakozósorok,  helyes  nyomvonal  és  térköz,  munkaszélesség). <b>Gép- és adatgyűjtés-beállítás</b> alkalmával a gépek megfelelő dokumentációs beállításait végezzük el, melyek a szolgáltatás teljes ideje alatt  elérhetők  (monitor beállítások,  traktor  munkagép  méretezése,  gépkezelők  helyes munkavégzéssel  kapcsolatos  tanácsadása).  A <b>dokumentációs  adatok  visszaellenőrzésekor</b> megvizsgáljuk a gép által szolgáltatott adatok helyességét, feltárjuk az esetleges problémákat, és javaslatot teszünk helyes dokumentáció kialakítására.</div>`
                },
                {
                    title: "Gépüzemeltetési előrejelzés és adatelemzések",
                    img: "gepuzemeltetesi_elorejelzes_es_adat_elemzesek.png",
                    modalImg: "gepuzemeltetesi_elorejelzes_es_adat_elemzesek_pop_up.png",
                    description: `
                           <p>
                            <div><b>Gépüzemeltetési analízis</b></div>
                            <div>Partnereink JDLink szolgáltatásra alkalmas gépeiből, a beérkező napi jelentésekből, illetve más forrásokból származó adatok alapján egy átfogó elemzést készítünk, ami fényt deríthet az előforduló hibákra. A szolgáltatáscsomag egy konzultációt tartalmaz.</div>
                           </p>
                           <p>
                            <div><b>Kombájn üzemanalízis</b></div>
                            <div>Kombájnok esetén egy veszteségelemző berendezéssel diagnosztikát futtatunk magán a gépen.  Az  elemzés  elvégeztével  javaslatot  teszünk  a  helyes  gépüzemeltetésre,  ezáltal elősegíthetjük a magasabb szintű gépkihasználtságot.</div>
                           </p>
                           <p>
                            <div><b>Gépüzemeltetési előrejelzés</b></div>
                            <div>A John Deer gépek rendelkeznek egy speciális előrejelző rendszerrel, amely JDLink adatok alapján képes figyelmeztetést küldeni a hibás gépbeállításról, vagy az esetlegesen előforduló magas üzemanyagfogyasztásról. Előnye, hogy mindig naprakész információt kapunk a gépek beállításairól, és költséghatékonyabban üzemeltethetjük a gépeinket.</div>
                        </p>`
                },
                {
                    title: "Partner Program",
                    img: "parner_program.png",
                    modalImg: "partner_program_pop_up.png",
                    description: `<div>A MIR Partner Program egy közel öt éves agrárinformatikai és több mint tíz éves műszaki informatikai tapasztalaton nyugvó tanácsadási rendszer. Partnerprogramunk célja, hogy segítse a digitális átállást egy mezőgazdasági üzem esetében, és hozzásegítse partnereinket a digitális eszközökkel támogatott termelésből realizálható minél magasabb többlethozamhoz. Programunk a gyakorlatban könnyen és gyorsan integrálható agro-informatikai eszközökkel, 21. századi csúcstechnológiákon alapuló innovatív szoftverrendszerekkel könnyítik partnereink mindennapi munkáját, és hozzájárulnak a fenntartható gazdálkodáshoz. A művelt terület nagyságától (ha) és a gépek számától függően rendeztük csomagokba a szolgáltatásokat.</div>`
                },
                {
                    title: "Digitális Farm",
                    img: "digitalis_farm.png",
                    modalImg: "digitalis_farm_pop_up.png",
                    description: `<div>A Digitális Farm magában foglalja a digitális eszközökkel támogatott mezőgazdasági műveletek során keletkezett adatok összegyűjtését, feldolgozását, elemzését és az ezek alapján történő döntéshozatalt a munkafolyamatok során. Saját fejlesztésű szenzoraink által gyűjtött adatok alapján szoftverjeink végzik a bonyolult számításokat, amelyek eredményeképpen jönnek létre a döntéstámogató információk.</div>`
                },
                {
                    title: "Hot Line segítségnyújtás",
                    img: "hot_line_segednyujtas.png",
                    modalImg: "hot_line_segednyujtas-pop_up.png",
                    description: `<div>Távoli hozzáféréssel biztosítjuk, hogy a gépüzemeltetéssel, precíziós gazdálkodás műszaki hátterével kapcsolatos kérdésekre gyors és szakszerű választ tudjunk adni. Ez a szolgáltatás szezonban a hét minden napján 07:00 és 17:00 között érhető el, szezonon kívül kizárólag hétköznapokon (kivéve munkaszüneti és ünnepnapok) érhető el a telefonos szolgáltatás.</div>`
                },
                {
                    title: "Gépüzemeltetési szolgáltatások",
                    img: "gepuzemeltetesi_szolgaltatasok.png",
                    modalImg: "gepuzemeltetesi_szolgaltatasok_pop_up.png",
                    description: `
                        <ul>
                            <li></b><b>Gépüzemeltetési tanácsadás:</b> Saját telephelyen maximum 2 fő részére és maximum 5 óra időtartam  alatt  elvégezzük  a  gép-  és  munkagép  dokumentációjának  és  kezelésének bemutatását.  A  rögzített  dokumentáció  az  alapja  a  pályázati  megfelelésnek  és  a döntéstámogatásnak.</li>
                            <li><b>Gépüzemeltetési analízis:</b> szakember ellenőrzi a precíziós gépek beállítását, és javaslatot tesz az esetleges hibás beállítások javítására.</li>
                            <li><b>Precíziós traktor működtetési csomag:</b> az egyes munkafolyamatok és a traktor-munkagépek helyes pótsúlyozására teszünk javaslatot, mindezt speciális tengelysúlymérők segítségével.</li>
                            <li><b>Éves gépüzemeltetési DATA elemzések:</b> a napi szintű jelentésekből készítünk összesítést, feltárjuk az esetleges üzemeltetéssel kapcsolatos hibákat, és javaslatot teszünk a javításukra.</li>
                        </ul>
                    `
                },
                {
                    title: "Belvízelvezetés tervezése és kivitelezése",
                    img: "belvizelvezetes_tervezes_es_kiviteles.png",
                    modalImg: "belvizelvezetes_tervezes_es_kiviteles_pop_up.png",
                    description: `<div>Az egyre gyakoribb szélsőséges időjárás miatt gyakran okoz problémát a belvizes foltok kialakulása sík területeken. Ennek a problémának a megoldására fejlesztettük ki a 3D vápanyitási technológiát (háromdimenziós adatokon nyugvó technológia), amellyel megtervezhetjük a táblán belüli belvíz elvezetését.  A  tábla  domborzatát  és  a  szükséges  információkat  figyelembe  véve  (pl.  meglévő csatornák, dűlőutak) cm-es pontossággal megtervezhetők a szükséges földmunkák és a tervezett csatornák kiépítése.</div>`
                },
                {
                    title: "Helyspecifikus szolgáltatások",
                    img: "helyspecifikus_szolgaltatasok.png",
                    modalImg: "helyspecifikus_szolgaltatasok_pop_up.png",
                    description: `
                        <p>Segítünk egy olyan térképsorozat elkészítésében, amellyel a tábla adottságainak megfelelően a termelést  optimalizálni  lehet.  A  táblakontúrnak  megfelelően  pontos  műholdfelvételekkel  és  a rendelkezésre álló termelési adatokkal elkészítjük a termőképességi térképet, amely megmutatja, hogy a tábla egyes részei milyen adottsággal bírnak. Ez segít a talajmintavételezésben, a tápanyagpótlásban vagy éppen a vetéstervezés folyamatában.</p>
                        <p>
                            <div><b>RTK támogatott talajmintavételezés</b></div>
                            <div>A  termőképességi  térkép  segítségével már  könnyedén  elkészítjük  a menedzsment  zóna lehatárolásait. A zónákat GPS-sel és automata fúró rendszerű talajmintavevővel végezzük a szabványnak  megfelelően.  A  mintavételi  folyamatot  feltöltjük  a MIRSoil alkalmazásunkba. Lehetőség van bővített talajvizsgálatra is, amellyel a talaj tápanyagtartalmának és a növény igényeinek megfelelően tervezheti meg a műtrágyázási folyamatot.</div>
                        </p>
                        <p>
                            <div><b>Precíziós tápanyag-előírási térkép</b></div>
                            <div>A termőképességi térkép által kinyert adatokat elemezve lehetőség van különféle, tápanyaggal kapcsolatos  precíziós  szemléletű  szaktanácsadás  igénybevételére.  A  tervezett termésmennyiségnek megfelelően elkészítjük a precíziós tápanyagszükségletet, amely mutatja az elérni kívánt termés mennyiségéhez szükséges N/P/K és CaCo3 szinteket. A tápanyaggal kapcsolatos  szaktanácsadás  között  megtalálható  az <b>ásványi  nitrogén  mérés</b>,  melynek keretében a mintavételt követő 1-2 napon belül növényspecifikus fejtrágyázási javaslatot tudunk adni. Emellett a <b>mésztrágyázás</b> folyamatával kapcsolatban is hasznos információkat, eredményeket  tudunk  kinyerni  a  termőképességi  térképből,  hiszen  a  meszezés,  mint tápanyag-utánpótlás a mai modern növénytermesztésben ismét egyre fontosabbá válik. A meszezés nem csak a talaj szerkezetét javítja, de a növény fejlődésében, termőképességében és a műtrágya hasznosulásában is kulcsfontosságú szerepet játszik. Hozzájárul a talaj megfelelő pH  értékének  beállításához  és  fenntartásához,  és  kivitelezése is  egyszerű.  A <b>precíziós műtrágyázás-előírási térkép</b> a tábla eltérő adottságait figyelembe véve mutatja meg a különböző területekre szabott tápanyag-kijuttatási tervet, melyet az arra alkalmas gépek végre tudnak hajtani.</div>
                        </p>
                        <p>
                            <div><b>Precíziós vetési előírás térképek</b></div>
                            <div>A kinyert adatokból pontosan meg tudjuk határozni, hogy a különböző földterületeken hogyan tervezzük meg a vetést. Minden terület más és más adottsággal rendelkezik, ezért fontos, hogy célzottan tudjunk ültetési tervet kidolgozni.</div>
                        </p>
                    `
                },
                {
                    title: "Digitális Farm Adatbázis",
                    img: "digitalis_farm_adatbazis.png",
                    modalImg: "digitalis_farm_adatbazis_pop_up.png",
                    description: `
                    <p>Több  éves  tapasztalattal  rendelkezünk  agrárinformatikai  rendszereket  támogató  adatbázisok kialakításában  és  üzemeltetésében,  így  a  szenzorokból  kinyert  adatok  tárolása  és  feldolgozása biztonságosan és zökkenőmentesen történik. Az Agrártech rendszer hatékony működését egy ilyen integrált,  részletes  adatbázis  biztosítja. Ez az adatbázis tartalmazza a gazdaságban használt inputanyagokat, táblákat és azok határvonalát, erő-és munkagépek listáját, stb. Az adatbázist feltöltjük az erre alkalmas gépek monitorjaiba további felhasználás céljából.</p>
                    <b>Gépüzemeltetési működtetési csomagok:</b>
                    <p><ul>
                        <li>precíziós traktorműködtetési csomag (törzsadatbázis, dokumentációs beállítás, szezon közbeni gépbeállítás, napi gépüzemeltetési jelentés, gépüzemeltetési adatelemzés)</li>
                        <li>precíziós     kombájnműködtetési csomag (veszteségelemzés és hozammérő, dokumentációs beállítás, szezon közbeni gépbeállítás, napi gépüzemeltetési jelentés)</li>
                        <li>precíziós önjáró szecskázó (dokumentációs beállítás, szezon közbeni gépbeállítás, napi gépüzemeltetési jelentés, gépüzemeltetési adatelemzés)</li>
                        <li>precíziós  önjáró silózó (dokumentációs  beállítás,  szezon  közbeni  gépbeállítás,  napi gépüzemeltetési jelentés, gépüzemeltetési adatelemzés)</li>
                        <li>precíziós önjáró permetező (dokumentációs beállítás, szezon közbeni gépbeállítás, napi gépüzemeltetési jelentés, gépüzemeltetési adatelemzés)</li>
                    </ul>
                    </p>
                    `
                },
                {
                    title: "Előrejelzés (növényvédelem)",
                    img: "elorejelzes_novenyvedelem.png",
                    modalImg: "elorejelzes_novenyvedelem_pop_up.png",
                    description: `
                        <div>Saját  területre  tervezett agrometeorológiai  állomásokkal  készülhet  fel  az  időjárási  változásokra, elkerülve az általuk okozott károkat. Az így kialakított állomások az alábbiakat tartalmazzák:</div>
                        <p>
                            <ul>
                                <li>talajnedvességet és talajhőmérsékletet mérő szenzor</li>
                                <li>levegő relatív páratartalmát és hőmérsékletét mérő eszköz</li>
                                <li>kozmikusbesugárzás-mérő</li>
                                <li>szélerősség- és iránymérő</li>
                                <li>csapadékmérő</li>
                                <li>levélnedvesség-mérő</li>
                            </ul>
                        </p>
                        <div>Szlogen: „Földmérés mesterfokon"</div>
                        <div>Ezek az eszközök különböző riasztási fokozatokkal segítik a termelőt, hogy időben fel tudjon készülni az esetleges időjárási változásokra.</div>
                    `
                },
                {
                    title: "Drón felvételek készítése",
                    img: "dron_felvetelek.png",
                    modalImg: "dron_felvetelek_pop_up.png",
                    description: `<div>Drón ortofotó-felvételek készülnek a termelő saját területéről, amelyek a drón adatfeltöltő applikáción keresztül betöltésre kerülnek a saját partnerfiókba. A termelő itt tudja megrendelni a számára szükséges elemzéseket (tőszámlálás, kártevők által okozott károk, 3D domborzati modell stb.), melyeket digitális formában küldünk meg (.shp és .pdf).</div>`
                },
            ]
        }
    }
}
</script>
