<template>
<div id="kapcsolat" class="content contact component">

    <div class="title-container d-flex justify-content-center">
        <img src="../assets/img/background/kapcsolat_background-2560.png" alt="Kapcsolatfelvételi űrlap" class="bg-img bg-img-large">
        <img src="../assets/img/background/kapcsolat_background-1920.png" alt="Kapcsolatfelvételi űrlap" class="bg-img bg-img-big">
        <img src="../assets/img/background/kapcsolat_background-1360.png" alt="Kapcsolatfelvételi űrlap" class="bg-img bg-img-medium">
        <img src="../assets/img/background/kapcsolat_background-768.png" alt="Kapcsolatfelvételi űrlap" class="bg-img bg-img-small">
        <h1 class="title col-md-10 col-xl-8 custom-text-shadow-2">Keresse fel a szakértőinket és ismerje meg, tesztelje le az <span>AGRÁRTECH</span> rendszerét!</h1>
    </div>

    <div class="contact-form-container base-padding">
        <div class="contact-form align-items-center shadow bg-white">
            <div class="d-flex flex-row flex-wrap align-items-stretch no-gutters contact-wrap">
                <div class="col-12 col-md-7 col-lg-8 p-0">
                    <div class="form h-100">
                        <h3 class="contact-text">Kapcsolatfelvétel szaktanácsadónkkal</h3>
                        <form method="post" action="mail.php" id="contactForm" name="contactForm" novalidate="novalidate">

                            <div class="form-group my-3">

                                <input v-model="emailForm.name" type="text" class="form-control" name="name" id="name" placeholder="Az Ön neve...">
                            </div>

                            <div class="form-group my-3">

                                <input :class="{'invalidEmailAddress': !validateEmail} " v-model="emailForm.email" type="text" class="form-control" name="email" id="email" placeholder="E-mail címe...">
                            </div>

                            <div class="form-group my-3">
                                <textarea v-model="emailForm.message" class="form-control" name="message" id="message" cols="30" rows="6" placeholder="Üzenet..."></textarea>
                            </div>

                            <input type="hidden" name="contact" value="contact">

                            <div v-if="urlHasEmailPath">
                                <p :class="successfullEmailSend ? 'successFullEmailSend' : 'unsuccessFullEmailSend'">
                                    {{emailStateMessage}}
                                </p>
                            </div>

                            <div>
                                <div class="form-group mt-3">
                                    <button type="submit" value="submit" class="button py-2 px-5"> Küldés </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div class="col-12 col-md-5 col-lg-4 p-0">
                    <div class="contact-info h-100 w-100">

                        <h3 class="pb-3">AGRÁRTECH iroda</h3>

                        <div class="d-flex align-items-center justify-content-between mb-4">
                            <div class="col-1 text-center">
                                <b-img alt="Telefon ikon" title="Telefon ikon" :src="require('../assets/img/contact/smartphone.svg') "></b-img>
                            </div>
                            <a class="text col-10 text-white" href="tel:36-70-776-1290">+36 70 776 1290</a>
                        </div>

                        <div class="d-flex align-items-center justify-content-between mb-4">
                            <div class="col-1 text-center">
                                <b-img  alt="Email ikon" title="Email ikon" :src="require('../assets/img/contact/email.svg')"></b-img>
                            </div>
                            <a class="text col-10 text-white" href="mailto:digitalisatallas@csihazrt.hu">digitalisatallas@csihazrt.hu </a>
                        </div>

                        <div class="d-flex align-items-center justify-content-between">
                            <div class="col-1 text-center">
                                <b-img alt="Térkép ikon" title="Térkép ikon" :src="require('../assets/img/contact/maps-and-flags.svg')"></b-img>
                            </div>
                            <span class="text col-10">6724 Szeged <br> Kossuth Lajos sgt. 52.</span>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="man">
            <b-img :src="require('../assets/img/contact/agrar_kaszas.svg')" alt="traktor"></b-img>
        </div>
        <b-img class="tractor" :src="require('../assets/img/contact/traktor_antracit.svg')" alt="traktor"></b-img>
    </div>
</div>
</template>

<script>
export default {
    name: "contact",
    data() {
        return {
            emailForm: {
                name: "",
                email: "",
                message: ""
            },
            successfullEmailSend: false,
            isEmailAddressValid: false
        }
    },
    computed: {
        validateEmail() {
            if (this.emailForm.email === "")
                return true;
            const regEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return regEx.test(this.emailForm.email);
        },
        urlHasEmailPath(){
            return (document.location.href.includes("sikeres#kapcsolat") || document.location.href.includes("sikertelen#kapcsolat"));
        }
    },
    created() {
        if (document.location.href.includes("sikertelen#kapcsolat")) {
            this.emailStateMessage = "Az üzenetet nem sikerült elküldeni. Kérjük, próbálja újra később.";
            this.successfullEmailSend = false;
        } 
        else if (document.location.href.includes("sikeres#kapcsolat")) {
            this.emailStateMessage = "Üzenetét sikeresen továbbítottuk.";
            this.successfullEmailSend = true;
        }
    }
}
</script>
