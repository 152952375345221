<template>
<div class="col-sm-8 col-lg-4 d-flex justify-content-center">
    <div class="card card-mobile-screen border-0 mb-5 align-self-sm-center align-self-lg-baseline">
        <div class="d-flex justify-content-center title-img-container">
            <b-img :src="require(`../assets/img/packages/packagePerks/${packageInfo.headerImg}`)" alt="" class="title-img" />
        </div>
        <div class="card-header d-flex justify-content-center">
            <div class="col-10 text-center">
                {{packageInfo.headerText}}
            </div>
        </div>
        <b-collapse :id="packageInfo.headerImg" v-html="packageInfo.content"></b-collapse>
        <span v-b-toggle="`${packageInfo.headerImg}`" class="icon-container d-flex align-items-end justify-content-center">
            <img src="../assets/img/servicePackages/zold_doboz_fehernyil.svg" class="icon up">
            <img src="../assets/img/servicePackages/mobil_zold_ikon.svg" class="icon down">
        </span>
    </div>
</div>
</template>

<script>
export default {
    props: ["packageInfo"]
}
</script>