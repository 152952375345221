<template>
<div id="csomagjaink" class="packages component">
    <div class="d-flex title-container ">
        <img src="../assets/img/packages/csomagjaink_bg_2560.png" alt="Informatikai megoldások a mezőgazdaságban" class="bg-img bg-img-large">
        <img src="../assets/img/packages/csomagjaink_bg_1920.png" alt="Informatikai megoldások a mezőgazdaságban" class="bg-img bg-img-big">
        <img src="../assets/img/packages/csomagjaink_bg_1360.png" alt="Informatikai megoldások a mezőgazdaságban" class="bg-img bg-img-medium">
        <img src="../assets/img/packages/csomagjaink_bg_768.png" alt="Informatikai megoldások a mezőgazdaságban" class="bg-img bg-img-small">
        <img src="../assets/img/packages/csomagjaink_bg_372.png" alt="Informatikai megoldások a mezőgazdaságban" class="bg-img bg-img-mobile">

        <div class="header-text d-flex align-items-start flex-column mt-xl-4 mt-3 base-padding w-100">
            <h1 class="packageTitle mb-lg-3 col-12 col-sm-9 col-xl-8">Válasszon csomagjaink közül!</h1>
            <div class="subHeader h1 col-12 col-sm-9 col-xl-8">Használja az <span class="agrartech">agrártech</span> nyújtotta előnyöket!</div>
            <ul class="features mt-lg-3 col-12 sm-9">
                <li>Szaktanácsadás a mezőgazdaság minden területén</li>
                <li>Precíziós Tápanyagtervezés</li>
                <li>Országos RTK hálózat</li>
                <li>Naprakész kimutatások</li>
                <li>Profitnövelés az informatika segítségével</li>
            </ul>
            <div class="col-8 col-md-12">
                <more-info-button class="d-none d-md-block mt-md-0" />
            </div>
        </div>
    </div>
    <div class="main-section d-flex flex-column">
        <div class="packagesAndOffersTitle align-self-center mt-3">
            Csomagjaink, ajánlataink
        </div>
        <div class="packageTypeSelector px-0 col-10 my-4 col-md-8 col-lg-5 col-xl-4 d-flex flex-row flex-wrap align-self-center">
            <div class="packageOffers pl-2 col-12 col-sm-6">Csomagajánlataink</div>
            <div class="customPackages col-12 col-sm-6"><a class="customPackagesLink" href="#kapcsolat">Egyéni csomag</a></div>
        </div>
        <div class="currentlySelectedPackage d-flex flex-column col-10 col-md-6 align-self-center ">
            <div>
                A most kiválasztott <br class="d-md-none" /> (2021. augusztus 1. - 2021. szeptember 1.)
            </div>
            <div>
                Starter csomagok előfizetési díjából <b> 10% kedvezményt </b> vehet igénybe az első évben.
            </div>
        </div>

        <div class="mt-3 d-flex flex-row flex-wrap justify-content-around pb-3 base-padding">
            <package-card v-for="(packageInfo, index) in getPackages" :key="index" :packageInfo="packageInfo"></package-card>
            <package-card-mobile v-for="packageInfo in getPackages" :key="packageInfo.headerImg" :packageInfo="packageInfo"></package-card-mobile>

        </div>
        <div class="moreOffers align-self-center">
            <a href="#kapcsolat">További ajánlatokért keresse szakértőinket!</a>
        </div>
        <div class="baseBackground">
            <img src="../assets/img/packages/csomagjaink__also_bg_2560.jpg" alt="Informatikai megoldások a mezőgazdaságban" class="bg-img bg-img-large">
            <img src="../assets/img/packages/csomagjaink__also_bg_1920.jpg" alt="Informatikai megoldások a mezőgazdaságban" class="bg-img bg-img-big">
            <img src="../assets/img/packages/csomagjaink__also_bg_1360.jpg" alt="Informatikai megoldások a mezőgazdaságban" class="bg-img bg-img-medium">
            <img src="../assets/img/packages/csomagjaink__also_bg_768.jpg" alt="Informatikai megoldások a mezőgazdaságban" class="bg-img bg-img-small">
            <img src="../assets/img/packages/csomagjaink__also_bg_372.jpg" alt="Informatikai megoldások a mezőgazdaságban" class="bg-img bg-img-mobile">
        </div>
    </div>
</div>
</template>

<script>
import MoreInfoButton from './MoreInfoButton.vue'
import packageCard from './packageCard.vue'
import packageCardMobile from './packageCardMobile.vue'
import digitalisFarmIkon from '../assets/img/packages/packagePerks/digitali_farm_ikon.png'
import adatGyujtesIkon from '../assets/img/packages/packagePerks/adatgyujtes_ikon.png'
import gepuzemeltetesIkon from '../assets/img/packages/packagePerks/Gepuzemeltetesi.png'
import mukodtetesiIkon from '../assets/img/packages/packagePerks/Gepuzemeltetesi_mukodtetesi_csomagok_ikon.png'
import agrartechIkon from '../assets/img/packages/packagePerks/Agrártech.png'
import belvizelvezetesIkon from '../assets/img/packages/packagePerks/Belvizelvezetes_ikon.png'
import novenyvedelemIkon from '../assets/img/packages/packagePerks/Elorejelzes_novenyvedelem_ikon.png'
import dronIkon from '../assets/img/packages/packagePerks/dron_felvetelek_ikon.png'

export default {
    components: {
        MoreInfoButton,
        packageCard,
        packageCardMobile

    },
    computed: {
        getPackages() {
            return [{
                    headerImg: "Basic.svg",
                    headerText: 'Egyszeri ingyenes "Talaj analízis" szolgáltatással*',
                    content: `<div class="cardContent d-flex flex-row">
                        <div class="col-2 col-md-3 px-2 text-center"><img src="${digitalisFarmIkon}" alt="digitális farm" title="digitális farm" class="packagePerkImage" /></div>
                        <div class="packagePerkList col-10 col-md-9 pl-0">
                            <div class="packagePerkListHeader">
                                digitális farm
                            </div>
                            <div class="packgaePerkListContent">
                                <ul>
                                    <li>Gépüzemeltetési tanácsadás</li>
                                    <li>Precíziós traktor működési csomag</li>
                                    <li>Éves gépüzemeltetési DATA elemzések</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="cardContent d-flex flex-row">
                        <div class="col-2 col-md-3 px-2 text-center"><img src="${adatGyujtesIkon}" class="packagePerkImage" alt="adatgyűjtés" title="adatgyűjtés"></div>
                        <div class="packagePerkList col-10 col-md-9 pl-0">
                            <div class="packagePerkListHeader">
                                adatgyűjtés, támogatás
                            </div>
                            <div class="packgaePerkListContent">
                                <ul>
                                    <li>Gép- és adatgyűjtés-beállítás</li>
                                    <li>Hot Line segítségnyújtás</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="cardContent d-flex flex-row">
                        <div class="col-2 col-md-3 px-2 text-center"><img src="${gepuzemeltetesIkon}" class="packagePerkImage" alt="gépüzemeltetés" title="gépüzemeltetés"></div>
                        <div class="packagePerkList col-10 col-md-9 pl-0">
                            <div class="packagePerkListHeader">
                                Gépüzemeltetési előrejelzés és adatelemzések
                            </div>
                            <div class="packgaePerkListContent">
                                <ul>
                                    <li>Gépüzemeltetési analízis</li>
                                    <li>Kombájn üzem analízis</li>
                                    <li>Gépüzemeltetési előrejelzés</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="cardContent d-flex flex-row">
                        <div class="packagePerkList offset-2 offset-md-3">
                            <ul class="packagePerkListHeader">
                                <li>Agrártech mir - digitális farmadatbázis-kezelés</li>
                            </ul>
                        </div>
                    </div>
                    <div class="cardContent d-flex flex-row">
                        <div class="col-2 col-md-3 px-2 text-center"><img src="${mukodtetesiIkon}" class="packagePerkImage" alt="működtetési csomagok" title="működtetési csomagok"></div>
                        <div class="packagePerkList col-10 col-md-9 pl-0">
                            <div class="packagePerkListHeader">
                                Gépüzemeltetési működtetési csomagok
                            </div>
                            <div class="packgaePerkListContent">
                                <ul>
                                    <li>Precíziós traktorműködtetési csomag</li>
                                    <li>Precíziós kombájnműködtetési csomag</li>
                                    <li>Precíziós önjáró szecskázó működtetési csomag</li>
                                    <li>Precíziós önjáró silózó működtetési csomag</li>
                                    <li>Precíziós önjáró permetező működtetési csomag</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="justify-content-center d-flex interestedInButton pb-4">
                        <button type="button" class="btn interestedBasic col-11 col-sm-5">
                            <a href="#kapcsolat">Érdekel</a>
                        </button>
                    </div>`
                },
                {
                    headerImg: "PRO.svg",
                    headerText: 'Egyszeri ingyenes "Talaj analízis" szolgáltatással*',
                    content: `<div class="cardContent d-flex flex-row">
                        <div class="col-2 col-md-3 px-2 text-center"><img src="${digitalisFarmIkon}" class="packagePerkImage" alt="digitális farm" title="digitális farm"></div>
                        <div class="packagePerkList col-10 col-md-9 pl-0">   
                            <div class="packagePerkListHeader">
                                digitális farm
                            </div>
                            <div class="packgaePerkListContent">
                                <ul>
                                    <li>Gépüzemeltetési tanácsadás</li>
                                    <li>Precíziós traktor működési csomag</li>
                                    <li>Éves gépüzemeltetési DATA elemzések</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="cardContent d-flex flex-row">
                        <div class="col-2 col-md-3 px-2 text-center"><img src="${adatGyujtesIkon}" class="packagePerkImage" alt="adatgyűjtés" title="adatgyűjtés"></div>
                        <div class="packagePerkList col-10 col-md-9 pl-0">
                            <div class="packagePerkListHeader">
                                adatgyűjtés, támogatás
                            </div>
                            <div class="packgaePerkListContent">
                                <ul>
                                    <li>Gép- és adatgyűjtés-beállítás</li>
                                    <li>Hot Line segítségnyújtás</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="cardContent d-flex flex-row">
                        <div class="col-2 col-md-3 px-2 text-center"><img src="${gepuzemeltetesIkon}" class="packagePerkImage" alt="gépüzemeltetés" title="gépüzemeltetés"></div>
                        <div class="packagePerkList col-10 col-md-9 pl-0">
                            <div class="packagePerkListHeader">
                                Gépüzemeltetési előrejelzés és adatelemzések
                            </div>
                            <div class="packgaePerkListContent">
                                <ul>
                                    <li>Gépüzemeltetési analízis</li>
                                    <li>Kombájn üzem analízis</li>
                                    <li>Gépüzemeltetési előrejelzés</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="cardContent d-flex flex-row">
                        <div class="packagePerkList offset-2 offset-md-3">
                            <ul class="packagePerkListHeader">
                                <li>Agrártech mir - digitális farmadatbázis-kezelés</li>
                            </ul>
                        </div>
                    </div>
                    <div class="cardContent d-flex flex-row">
                        <div class="col-2 col-md-3 px-2 text-center"><img src="${mukodtetesiIkon}" class="packagePerkImage" alt="működtetési csomagok" title="működtetési csomagok"></div>
                        <div class="packagePerkList col-10 col-md-9 pl-0">
                            <div class="packagePerkListHeader">
                                Gépüzemeltetési működtetési csomagok
                            </div>
                            <div class="packgaePerkListContent">
                                <ul>
                                    <li>Precíziós traktorműködtetési csomag</li>
                                    <li>Precíziós kombájnműködtetési csomag</li>
                                    <li>Precíziós önjáró szecskázó működtetési csomag</li>
                                    <li>Precíziós önjáró silózó működtetési csomag</li>
                                    <li>Precíziós önjáró permetező működtetési csomag</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="cardContent d-flex flex-row">
                        <div class="col-2 col-md-3 px-2 text-center"><img src="${agrartechIkon}" class="packagePerkImage" alt="adatbázis-kezelés" title="adatbázis-kezelés"></div>
                        <div class="packagePerkList col-10 col-md-9 pl-0">
                            <div class="packagePerkListHeader">
                                Agrártech mir- helyspecifikus szolgáltatások
                            </div>
                            <div class="packgaePerkListContent">
                                <ul>
                                    <li>RTK támogatott talajmintavételezés</li>
                                    <li>Precíziós tápanyag előírás térkép</li>
                                    <li>Precíziós vetési előírás térképek</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="justify-content-center d-flex interestedInButton pb-4">
                        <button type="button" class="btn interestedPRO col-11 col-sm-5">
                            <a href="#kapcsolat">Érdekel</a>
                        </button>
                    </div>`
                },
                {
                    headerImg: "Expert.svg",
                    headerText: 'Egyszeri ingyenes "Talaj analízis" szolgáltatással*',
                    content: `<div class="cardContent d-flex flex-row">
                        <div class="col-2 col-md-3 px-2 text-center"><img src="${digitalisFarmIkon}" class="packagePerkImage" alt="digitális farm" title="digitális farm"></div>
                        <div class="packagePerkList col-10 col-md-9 pl-0">
                            <div class="packagePerkListHeader">
                                digitális farm
                            </div>
                            <div class="packgaePerkListContent">
                                <ul>
                                    <li>Gépüzemeltetési tanácsadás</li>
                                    <li>Precíziós traktor működési csomag</li>
                                    <li>Éves gépüzemeltetési DATA elemzések</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="cardContent d-flex flex-row">
                        <div class="col-2 col-md-3 px-2 text-center"><img src="${adatGyujtesIkon}" class="packagePerkImage" alt="adatgyűjtés" title="adatgyűjtés"></div>
                        <div class="packagePerkList col-10 col-md-9 pl-0">
                            <div class="packagePerkListHeader">
                                adatgyűjtés, támogatás
                            </div>
                            <div class="packgaePerkListContent">
                                <ul class="pl-3">
                                    <li>Gép- és adatgyűjtés-beállítás</li>
                                    <li>Hot Line segítségnyújtás</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="cardContent d-flex flex-row">
                        <div class="col-2 col-md-3 px-2 text-center"><img src="${gepuzemeltetesIkon}" class="packagePerkImage" alt="gépüzemeltetés" title="gépüzemeltetés"></div>
                        <div class="packagePerkList col-10 col-md-9 pl-0">
                            <div class="packagePerkListHeader">
                                Gépüzemeltetési előrejelzés és adatelemzések
                            </div>
                            <div class="packgaePerkListContent">
                                <ul>
                                    <li>Gépüzemeltetési analízis</li>
                                    <li>Kombájn üzem analízis</li>
                                    <li>Gépüzemeltetési előrejelzés</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="cardContent d-flex flex-row">
                        <div class="packagePerkList offset-2 offset-md-3">
                            <ul class="packagePerkListHeader">
                                <li>Agrártech mir - digitális farmadatbázis-kezelés</li>
                            </ul>
                        </div>
                    </div>
                    <div class="cardContent d-flex flex-row">
                        <div class="col-2 col-md-3 px-2 text-center"><img src="${mukodtetesiIkon}" class="packagePerkImage" alt="adatbázis-kezelés" title="adatbázis-kezelés"></div>
                        <div class="packagePerkList col-10 col-md-9 pl-0">
                            <div class="packagePerkListHeader">
                                Gépüzemeltetési működtetési csomagok
                            </div>
                            <div class="packgaePerkListContent">
                                <ul>
                                    <li>Precíziós traktorműködtetési csomag</li>
                                    <li>Precíziós kombájnműködtetési csomag</li>
                                    <li>Precíziós önjáró szecskázó működtetési csomag</li>
                                    <li>Precíziós önjáró silózó működtetési csomag</li>
                                    <li>Precíziós önjáró permetező működtetési csomag</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="cardContent d-flex flex-row">
                        <div class="col-2 col-md-3 px-2 text-center"><img src="${agrartechIkon}" class="packagePerkImage" alt="működtetési csomagok" title="működtetési csomagok"></div>
                        <div class="packagePerkList col-10 col-md-9 pl-0">
                            <div class="packagePerkListHeader">
                                Agrártech mir - helyspecifikus szolgáltatások
                            </div>
                            <div class="packgaePerkListContent">
                                <ul>
                                    <li>RTK támogatott talajmintavételezés</li>
                                    <li>Precíziós tápanyag előírás térkép</li>
                                    <li>Precíziós vetési előírás térképek</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="cardContent d-flex flex-row">
                        <div class="col-2 col-md-3 px-2 text-center"><img src="${belvizelvezetesIkon}" class="packagePerkImage" alt="belvízelvezetés" title="belvízelvezetés"></div>
                        <div class="packagePerkList col-10 col-md-9 pl-0">
                            <ul class="packagePerkListHeader">
                                <li>Agrártech mir - Belvízelvezetés tervezés és kivitelezési szolgáltatás</li>
                            </ul>
                        </div>
                    </div>
                    <div class="cardContent d-flex flex-row">
                        <div class="col-2 col-md-3 px-2 text-center"><img src="${novenyvedelemIkon}" class="packagePerkImage" alt="előrejelzés" title="előrejelzés"></div>
                        <div class="packagePerkList col-10 col-md-9 pl-0">
                            <ul class="packagePerkListHeader">
                                <li>Agrártech mir - előrejelzés (növényvédelmi, termelő telephelyi adatok alapján)</li>
                            </ul>
                        </div>
                    </div>
                    <div class="cardContent d-flex flex-row">
                        <div class="col-2 col-md-3 px-2 text-center"><img src="${dronIkon}" class="packagePerkImage" alt="drón felvételek" title="drón felvételek"></div>
                        <div class="packagePerkList col-10 col-md-9 pl-0">
                            <ul class="packagePerkListHeader">
                                <li>Agrártech mir - Drón felvételek készítése</li>
                            </ul>
                        </div>
                    </div>
                    <div class="justify-content-center d-flex interestedInButton pb-4">
                        <button type="button" class="btn interestedExpert col-11 col-sm-5">
                            <a href="#kapcsolat">Érdekel</a>
                        </button>
                    </div>`
                }
            ]
        }
    }
}
</script>
